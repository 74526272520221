import React from 'react';
import MetaTags from 'react-meta-tags';

import PageHeader from './PageHeader';
import PageFooter from './PageFooter';
import PageBody from './PageBody';
import OfficeMapComponent from './OfficeMapComponent'
import SidebarComponent from './SidebarComponent'

class HomePage extends React.Component {
    renderPageBodyContent = () => {
        return (
            <React.Fragment>
                <div className="mainBlock mainBlockBlue">
                    <h1>Feng Cai. <i>Realtor, Broker, Licensed in TN</i></h1>
                    <p>   
                        My name is Feng Cai. I am a Real Estate Broker licensed in TN. I specialize in selling and buying homes! 
                    </p>
                    <p>
                        Whether you are buying your first home, relocating to Memphis, or selling your home to move out of Memphis, I can help you get the job done perfectly! 
                    </p>
                    <p> 
                        I serve all markets surround Memphis area: Arlington, Bartlett, Collierville, Cordova, Lakeland, Memphis.
                    </p>
                </div>
                <div className="mainBlock mainBlockGrey">
                    <h2>Why choose Feng</h2>
                    <ul className="square">
                        <li>
                            <i>FLEXIBLE TIME</i> - A schedule that works for you!
                        </li>
                        <li>
                            <i>KNOWLEDGE</i> - An experienced <b>broker</b> licensed in TN! Having lived in the Memphis area for over 18 years!
                        </li>
                        <li>
                            <i>SATISFACTION GUARANTEED</i> - Providing excellent and satisfactory customer services is always my #1 goal!
                        </li>
                        <li>
                            <i>CARE</i> - I will be with you every step of the way, from showing properties to closing! I can help taking care of everything in between!
                        </li>
                    </ul>
                </div>
                <div className="mainBlock mainBlockBlue">
                    <h2>Feng's Real Estate Background and Credentials</h2>
                    <ul className="square">
                        <li>
                            <i>RENE</i> - Certified Real Estate Negotiation Expert
                        </li>        
                        <li>
                            <i>Real Estate Inspector</i> - Certified Professional Real Estate Inspector
                        </li>
                    </ul>
                </div>
                <div className="mainBlock mainBlockGrey">
                    <h2>Referrals</h2>
                    <p>Like my services? Please recommend me to your friends and family members. A referral from you is the highest compliment I can receive!</p>
                    <div className="text-center">
                        <a href="/business-card.png" data-lightbox="fcai-business-card" data-title="Need to sell your house or buy a new house in Memphis? Feng can help!">
                            <img src="/business-card.png" className="businessCard"/>
                        </a>
                        <br/>
                        <div className="copyWidgets">
                            <p id="visibleBizCardUrl">https://feng.properties/business-card.png</p>
                            <button className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent businessCardCopyBtn" data-clipboard-target="#visibleBizCardUrl">
                                Copy to clipboard
                            </button>
                        </div>
                    </div>
                </div>
                {/*<div className="mainBlock mainBlockBlue">
                    <OfficeMapComponent></OfficeMapComponent>
                </div>*/}
            </React.Fragment>);
    }

    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>Real Estate Broker in Memphis TN | Feng</title>
                    <meta name="description" content="Real estate broker to help you buying or selling homes in Memphis area." />
                    <link href="/" rel="cannonical" />
                </MetaTags>
                <PageHeader />
                <div className="container-fluid bodyWrapper">
                    <div className="row reorder-xs">
                        <div className="col-xs-12 col-md-8">
                            <PageBody renderPageBodyContent={this.renderPageBodyContent} />
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <SidebarComponent hideSidebarForm={false}></SidebarComponent>
                        </div>
                    </div>
                </div>
                <PageFooter />
            </React.Fragment>
        );
    }
}


export default HomePage;